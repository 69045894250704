<template>
  <section id="MediaMesh">
    <ExperiencesCarousel :carouselImages="carouselImages" />
  </section>
</template>

<script>
  import ExperiencesCarousel from '@/components/global/ExperiencesCarousel'

  export default {
    name: "MediaMesh",
    components: {
      ExperiencesCarousel
    },
    data() {
      return {
        carouselImages: [
          {
            image: 'Experiences/Branding/media-mesh-1.jpg',
            title: 'Media Mesh',
            description: 'At 78’H x 347’W and facing the Las Vegas Strip, the Media Mesh makes an undeniably powerful statement as guests arrive.'
          },
          {
            image: 'Experiences/Branding/media-mesh-2.jpg',
            title: 'Media Mesh',
            description: 'Capture attention and create excitement by taking over the side of Allegiant Stadium with custom content.'
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  
</style>